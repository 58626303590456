<script setup lang="ts">
import { computed } from 'vue'
import { TransitionChild, TransitionRoot } from '@headlessui/vue'
import { createReusableTemplate, createTemplatePromise, useMouseInElement, useStorage } from '@vueuse/core'
import { isCurrent } from './AppMenuExpandableNavigationList.vue'
import { mergeConfig } from '#ui/utils'
// @ts-expect-error untyped
import appConfig from '#build/app.config'
import { slideover } from '#ui/ui.config'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  expanded: boolean
}>()

const modelValue = defineModel<'tools-and-services' | 'forgd-academy' | null | undefined>()
const config = mergeConfig(appConfig.ui.strategy, appConfig.ui.slideover, slideover)
const { ui, attrs } = useUI('slideover', computed(() => ({
  wrapper: `transform ${props.expanded ? 'translate-x-[260px]' : 'translate-x-[52px]'} transition-all`,
  width: 'max-w-[280px]',
  overlay: {
    background: 'bg-gray-900/10',
  },
})), config, ref('')) as Record<string, Ref<Record<string, any>>>

const auth = useAuth()
const { project } = storeToRefs(auth)
const teammateAccessWall = useTeammateAccessWall()
const skipGoogleSheetConfirm = useStorage<boolean>('forgd:nav:google-sheet-confirm', false)
const toolsAndServicesTab = useStorage<number>('forgd:nav:tools-services-tab', project.value?.tokenListed ? 1 : 0)

onMounted(() => {
  let mouseoverHandle: ReturnType<typeof useMouseInElement>
  watch(modelValue, () => {
    if (modelValue.value === null) {
      mouseoverHandle?.stop()
      return
    }
    // @ts-expect-error untyped - accessing outside of the component
    mouseoverHandle = useMouseInElement(document.querySelector('main[data-inertable]')!, {
      onEnter() {
        modelValue.value = null
      },
    })
  }, {
    immediate: true,
  })
})

const transitionClass = computed(() => {
  const enterFrom = ui.value.translate.left
  const leaveTo = ui.value.translate.left
  return {
    ...ui.value.transition,
    enterFrom,
    enterTo: ui.value.translate.base,
    leaveFrom: ui.value.translate.base,
    leaveTo,
  }
})

function handleWrapperMouseover(event: any) {
  if ('closeOnMouseover' in event.target.dataset && modelValue.value) {
    modelValue.value = null
  }
}

const GoogleSheetsConfirmModal = createTemplatePromise<boolean>()
const [DefineNavList, NavList] = createReusableTemplate<{ items: { to: string, label: string, icon?: any }[] }>()
const [DefineNavTitle, NavTitle] = createReusableTemplate<{ label: string, to?: string, icon?: any }>()

async function handleLinkClick(to: string) {
  if (to === '/partner-activity-lead/amm-2' && !auth.isOrganizationOwner) {
    teammateAccessWall.show()
    return
  }

  if (to.startsWith('https://docs.google.com/spreadsheets')) {
    if (skipGoogleSheetConfirm.value || await GoogleSheetsConfirmModal.start()) {
      window.open(to, '_blank')
    }
    return
  }
  to.startsWith('http') ? window.open(to, '_blank') : navigateTo(to)
}
</script>

<script lang="ts">
export const preTgeNav = [
  {
    label: 'Tokenomics & Protocol',
    icon: 'i-heroicons-cube',
    children: [
      { label: 'Token Designer', to: '/token-designer' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/pre-tge-smart-contract-protocol' },
      { label: 'Token Creation & Mint', to: '/partner-schedule/magna' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
      { label: 'Multi-Sig Token Custody', to: '/partner-schedule/squads' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/13TsO0y64pvv1mAbParQjK7AIIK0FxICc3CtBIQd3NTk/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
      { label: 'Go-to-Market Budgeting', to: 'https://docs.google.com/spreadsheets/d/1P0RhK4QYrTt9cqfCpf46qV1FnpH82N7CtlYkei3ISgU/edit?gid=1931257942#gid=1931257942' },
      { label: 'Public Sale, Launchpad', to: '/partner-schedule/legion' },
    ],
  },
  {
    label: 'Go-to-Market Partnership',
    icon: 'i-heroicons-globe-alt',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      { label: 'Engage a Market Maker', to: '/engage-a-market-maker' },
      { label: 'Exchange Listing', to: 'https://docs.google.com/spreadsheets/d/1mwjO3l9oJ2Tsb2VUxmLScL-dRZXtJbm_i0-yf66kIq0/edit?usp=sharing' },
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
      { label: 'Launch Checklist', to: 'https://docs.google.com/spreadsheets/d/1zo0zxfqXzvivroHi38jO0wHXLvpmRPO1dc6BRMMsB14/edit?usp=sharing' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'Entity formation & incorporation', to: '/partner-schedule/cavenwell' },
      { label: 'External General Counsel', to: '/partner-schedule/cavenwell' },
      { label: 'Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]

export const listedTokenNav = [
  {
    label: 'Analysis & Insights',
    icon: 'i-heroicons-light-bulb',
    children: [
      { label: 'Market Maker Monitoring', to: '/market-maker-monitoring/start' },
      { label: 'Token Unlock Monitoring', to: 'https://docs.google.com/spreadsheets/d/17ZMf3rHRcBqiMszPM0j6mMc8lF1E-RuEjMGqhPRXBNw/edit?gid=1560824262#gid=1560824262' },
      { label: 'Volume, Liquidity, & Price Performance', to: '/partner-activity-lead/volume-liquidity-price-performance' },
    ],
  },
  {
    label: 'Performance Optimization',
    icon: 'i-heroicons-chart-bar-square',
    children: [
      { label: 'Tokenomics & Demand Drivers', to: '/partner-activity-lead/tokenomics-demand-drivers' },
      // { label: 'Demand Driver Optimization', to: '/token-designer' },
      { label: 'AMM²', to: '/partner-activity-lead/amm-2' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/post-tge-smart-contract-protocol' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
    ],
  },
  {
    label: 'Strategic Partnership',
    icon: 'i-heroicons-puzzle-piece',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      { label: 'Engage a Market Maker', to: '/engage-a-market-maker' },
      { label: 'Exchange Listing', to: 'https://docs.google.com/spreadsheets/d/1mwjO3l9oJ2Tsb2VUxmLScL-dRZXtJbm_i0-yf66kIq0/edit?usp=sharing' },
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/1xElL1SBH88T1xyxTCpne2qPfJy7vouoDp2UZOzsO18A/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'External General Counsel', to: '/partner-schedule/cavenwell' },
      { label: 'Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]

export const forgdAcademyNav = [
  {
    label: 'How to Guides',
    icon: resolveComponent('IconPathFinder'),
    children: [
      { label: 'PathFinder', to: '/pathfinder' },
    ],
  },
  {
    label: 'Other Readings',
    icon: 'i-heroicons-newspaper',
    children: [
      { label: 'Forgd Products FAQs', to: 'https://forgd.com/faq#tools' },
      { label: 'About Forgd FAQs', to: 'https://forgd.com/faq#about' },
      { label: 'Forgd Blog', to: '/https://forgd.com/blog' },
    ],
  },
]
</script>

<template>
  <DefineNavList v-slot="{ items }">
    <ul>
      <li v-for="(child, childKey) in items" :key="childKey">
        <ULink :to="child.to" class="flex items-center justify-between w-full py-2 px-3 text-forgd-gray-600 hover:bg-forgd-neutral-400 transition rounded" @click.prevent="handleLinkClick(child.to)">
          <div>{{ child.label }}</div>
          <UIcon v-if="child.to?.startsWith('http')" name="i-heroicons-arrow-up-right" />
          <svg v-else-if="isCurrent(child, true)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="ml-1">
            <circle cx="4" cy="4" r="4" fill="#2D73FF" />
          </svg>
        </ULink>
      </li>
    </ul>
  </DefineNavList>
  <DefineNavTitle v-slot="{ label, icon }">
    <div class="flex items-center text-forgd-primary-900 gap-2 mb-2">
      <UIcon v-if="typeof icon === 'string'" :name="icon" class="w-5 h-5 text--bgd-200" />
      <component :is="icon" v-else class="w-5 h-5" color="currentColor" />
      <div class="font-semibold">
        {{ label }}
      </div>
    </div>
  </DefineNavTitle>
  <TransitionRoot as="template" appear :show="modelValue !== null">
    <div :class="[ui.wrapper]" v-bind="attrs" data-close-on-mouseover @mouseover="handleWrapperMouseover">
      <TransitionChild as="template" appear v-bind="transitionClass">
        <div class="bg-white h-full" :class="[ui.base, ui.width, ui.background, ui.ring, ui.padding]">
          <div class="text-xs relative max-h-screen overflow-y-auto light-scrollbar z-10">
            <template v-if="modelValue === 'tools-and-services'">
              <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
                <div class="text-sm mb-2 font-semibold text-forgd-primary-900">
                  <div>Tools & Services</div>
                </div>
                <UTabs
                  v-model="toolsAndServicesTab"
                  class="w-full mb-0 bg-white space-y-0"
                  :ui="{ list: { background: 'bg-white ring-forgd-gray-350 ring-1', marker: { background: 'bg-forgd-accent-900' }, tab: { active: 'text-white', size: 'text-xs', font: 'font-semibold' } } }"
                  :items="[{ label: 'Pre-TGE Token' }, { label: 'Listed Token' }]"
                />
              </div>
              <div class="p-4">
                <nav v-if="toolsAndServicesTab === 0" aria-label="Pre TGE Token Navigation" class="space-y-4">
                  <div v-for="(item, key) in preTgeNav" :key="key">
                    <NavTitle :label="item.label" :icon="item.icon" />
                    <NavList :items="item.children" />
                  </div>
                </nav>
                <nav v-else-if="toolsAndServicesTab === 1" aria-label="Listed Token Navigation" class="space-y-4">
                  <div v-for="(item, key) in listedTokenNav" :key="key">
                    <NavTitle :label="item.label" :icon="item.icon" />
                    <NavList :items="item.children" />
                  </div>
                </nav>
              </div>
            </template>
            <nav v-else aria-label="Forgd Academy Navigation">
              <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
                <div class="text-sm font-semibold text-forgd-primary-900">
                  <div>Forgd Academy</div>
                </div>
              </div>
              <div v-for="(item, key) in forgdAcademyNav" :key="key" class="p-4">
                <NavTitle :label="item.label" :icon="item.icon" />
                <NavList :items="item.children" />
              </div>
            </nav>
          </div>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
  <GoogleSheetsConfirmModal v-slot="{ resolve }">
    <UiModal
      is-open
    >
      <div>
        <UiStack>
          <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mb-5">
              <path d="M33.3332 11.5701V32.6666C33.3332 34.8757 31.5423 36.6666 29.3332 36.6666H10.6665C8.45737 36.6666 6.6665 34.8757 6.6665 32.6666V7.33325C6.6665 5.12411 8.45737 3.33325 10.6665 3.33325H24.3507M33.3332 11.5701L24.3507 3.33325M33.3332 11.5701H24.3507V3.33325M20.1402 19.2921H12.2805V23.2818M20.1402 19.2921H27.9998V23.2818M20.1402 19.2921V27.2715M20.1402 27.2715H27.9998V23.2818M20.1402 27.2715H12.2805V23.2818M27.9998 23.2818H12.2805" stroke="#057A55" stroke-width="1.5" />
            </svg>
            <div class="mb-3 font-semibold text-xl text-primary-900">
              You are about to open a Forgd workbook in Google Sheets in a new tab
            </div>
            <div class="mb-2 text-gray-600 text-base">
              Create a copy of the file on your local device (File > Make a Copy) before editing. Your session on Forgd will remain active.
            </div>
            <div class="text-gray-600 text-base">
              Would you like to continue?
            </div>
          </div>
          <div class="flex items-center w-full gap-3">
            <UiButton variant="outline" class="w-1/2" @click="resolve(false)">
              Cancel
            </UiButton>
            <UiButton variant="solid" class="w-1/2" @click="resolve(true)">
              Yes, Continue
            </UiButton>
          </div>
          <UiRule />
          <UCheckbox v-model="skipGoogleSheetConfirm" label="Don't show this message again" />
          <UiAlert type="info">
            <template #title>
              If you have questions on how to edit the prototype, <NuxtLink to="https://calendly.com/forgd/forgd-consultation" target="_blank" class="underline text-forgd-primary-300">
                schedule a consultation
              </NuxtLink>.
            </template>
          </UiAlert>
        </UiStack>
      </div>
    </UiModal>
  </GoogleSheetsConfirmModal>
</template>
